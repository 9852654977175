export const environment = {
  production: false,
  cognito: {
    UserPoolId: 'us-west-2_Ys3mIuzzB',
    ClientId: '1pq70ikgfltev39em46ecpp63l',
    DeviceName: 'MoYaWebAngular' //device name for cognito sessions
  },
  apiGateway: {
    domain: 'd8wy9n36h5.execute-api.us-west-2.amazonaws.com',
    stage: 'dev'
  }
}